import { createVNode, computed} from 'vue'

import {Stage, Layer, Group, Text} from 'konva';
import store from '@/store/';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { Modal } from 'ant-design-vue';
import {removeRuler} from "@/api/index.js";

export let lineLayer = null;

export const drawLine = {
    init:(width=localStorage.getItem('canvasWidth')*1,height=localStorage.getItem('canvasHeight')*1,stage)=>{
        lineLayer = new Layer();

        stage.find('.cloudline').each((tr)=>{
            tr.destroy();
            stage.batchDraw();
        })

        drawLine.draw(lineLayer,stage);
        stage.add(lineLayer)
    },
    draw:(layer=lineLayer,stage)=>{
        // console.log("draw line")
        let width=localStorage.getItem('canvasWidth')*1;
        let height=localStorage.getItem('canvasHeight')*1;
        console.log("draw line: ",width,height)
        const selectCategory = computed(() => store.state.selectCategory);
        const lists = computed(() => store.state.lineLists);
        const radio = width/selectCategory.value.width;

        lists.value.map((rect)=>{
            //矩形在页面显示的宽高
            let vWidth,vHeight;
            if(rect.direction==='hor'){
                vWidth = rect.width*radio;
                vHeight = 2000*radio;
            }else{
                vWidth = 2000*radio;
                vHeight = rect.width*radio;
            }
            
            //创建一个分组
            let group = new Group({
                x: rect.left*radio+0.5,
                y: rect.top*radio+0.5,
                width: vWidth,
                height: vHeight,
                type:'status'+rect.status,
                name:"cloudline",
                draggable: false,
                opacity:0.7,
                dragBoundFunc:(pos)=>{
                    let x = pos.x;
                    let y = pos.y;
                    if(x<=0){
                        x =0;
                    }
                    if(x>=width-group.attrs.width){
                        x =width-group.attrs.width;
                    }
                    if(y<=0){
                        y =0;
                    }
                    if(y>=height-group.attrs.height){
                        y =height-group.attrs.height;
                    }
                    return {
                        x:x,
                        y:y
                    }
                }
            });

            // group.on('mousedown', function () {
            //     selectGrout = group;
            //     store.commit("setSelectGroup",selectGrout);
            // });
            // group.on('mouseover', function () {
            //     const selectIcon= layer.find("#icon"+rect.id)[0];
            //     // const editIcon= layer.find("#icon"+rect.id)[1];
        
            //     if(selectIcon){
            //         selectIcon.opacity(0.5);
            //     }
            //     layer.draw();
            // });
        

            let fillStyle = '#fff';
            let boxX = vWidth/2 - 13
            let boxY = 0
            if(rect.direction==='ver'){
                boxX = 0
                boxY =  vHeight/2 - 13
            }
            //画矩形
            let box = new Konva.Rect({
                x: boxX,
                y: boxY,
                width: 26,
                height: 26,
                fill: fillStyle,
                stroke: '#000',
                strokeWidth: 0,
                opacity:0
            });
            group.add(box);
            //画矩形四条边
            let line; 
            if(rect.direction==='hor'){
                line = new Konva.Line({
                    x: 0, y: 0,
                    opacity:1,
                    points: [0,0,0+vWidth, 0],
                    stroke: "#111",
                    strokeWidth: 1,
                    dash:[]
                });
            }else{
                line = new Konva.Line({
                    x: 0, y: 0,
                    opacity:1,
                    points: [0,0,0,0+vHeight],
                    stroke: "#111",
                    strokeWidth: 1,
                    dash:[]
                });
            }
            
            group.add(line);

            let titleText;
            // 画展位号
            if(rect.direction==='hor'){
                titleText = new Text({
                    x:0, y:-14,
                    align:'center',
                    width: vWidth,
                    text:rect.width,
                    fill:'#666', fontSize:12
                })
            }else{
                titleText = new Text({
                    x:-4, y:0,
                    align:'center',
                    width: vHeight,
                    text:rect.width,
                    fill:'#666', fontSize:12,
                    rotation:90
                })
            }
        
            group.add(titleText);

            let iconWidth = 16;  //操作按钮宽度
            //画操作相关功能，如查看详情，购买展位等
            let imageObj = new Image();
            let delicon;
            imageObj.onload = function() {
                console.log("imageObj.onload")
                if(rect.direction==='hor'){
                    delicon = new Konva.Image({
                        x: vWidth/2 - iconWidth/2,
                        y: 3,
                        image: imageObj,
                        width: iconWidth,
                        height: iconWidth,
                        opacity:0.6,
                        // name:'name'+rect.id,
                        id:"icon"+rect.id
                    });
                }else{
                    delicon = new Konva.Image({
                        x: 3,
                        y: vHeight/2 - iconWidth/2,
                        image: imageObj,
                        width: iconWidth,
                        height: iconWidth,
                        opacity:0.6,
                        // name:'icon'+rect.status,
                        id:"icon"+rect.id
                    });
                }
                group.add(delicon);
                // layer.batchDraw();
                layer.draw();
                //删除展位
                delicon.on('click' , (e)=>{
                    console.log(e);
                    drawLine.removeOne(e,rect)
                    // Modal.confirm({
                    //     title: '删除展位?',
                    //     icon: createVNode(ExclamationCircleOutlined),
                    //     content: "展位删除不可恢复，请确认！",
                    //     okText: '删除',
                    //     cancelText:"取消",
                    //     onOk:() => {
                    //         console.log(rect);
                    //         removeRuler({token:localStorage.getItem('mtoken'), category_id:rect.category_id, showroom_ruler_id:rect.id}).then(res=>{
                    //             store.dispatch("getLists",selectCategory.value.id);
                    //             e.target.findAncestors('Group')[0].remove();
                    //             layer.batchDraw();
                    //         });
                    //     }
                    // });
                })
                delicon.on('mousemove', function () {
                    document.body.style.cursor = 'pointer';
                });
            };
            
            imageObj.src = require('@/assets/icon/del.png');
            layer.add(group);

            box.on('click' , (e)=>{
                console.log(e);
                drawLine.removeOne(e,rect)
            })
            box.on('mousemove', function () {
                document.body.style.cursor = 'pointer';
            });
        })

        return layer
    },
    removeOne:(e,rect)=>{
        const selectCategory = computed(() => store.state.selectCategory);
        // Modal.confirm({
        //     title: '删除标尺?',
        //     icon: createVNode(ExclamationCircleOutlined),
        //     content: "展位删除不可恢复，请确认！",
        //     okText: '删除',
        //     cancelText:"取消",
        //     onOk:() => {
        //         console.log(rect);
                removeRuler({token:localStorage.getItem('mtoken'), category_id:rect.category_id, showroom_ruler_id:rect.id}).then(res=>{
                    store.dispatch("getLineLists",selectCategory.value.id);
                    e.target.findAncestors('Group')[0].remove();
                    lineLayer.batchDraw();
                });
        //     }
        // });
    },
    update:()=>{
        drawLine.removeAll();
        drawLine.draw(lineLayer)
    },
    removeAll:()=>{
        lineLayer.destroyChildren();
    }
}