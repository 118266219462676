// import { ref,watch,computed } from 'vue'
import { message } from 'ant-design-vue';
import {Stage} from 'konva';

import {drawGuid} from './guidinit .js';  //网格
import {drawRect} from './rect.js'; //矩形
import {drawLine} from './line.js'; //展位间距
import {drawTempRect} from './tempRect.js'; //未上传的矩形
import {drawRecting} from './draw.js'; //绘制中的矩形
import {drawPaddingRuler} from './paddingRuler.js'; //内边距标尺
import store from '@/store/';
import state from '../../../store/state.js';
// import {usekeyBoard} from '@/use/diy-use.js'

export let stage=null;
// export let guidLayer = null;
// export let rectLayer =null;
// export let lineLayer =null;
export let drawRectingLayer = null;
// export let paddingRulerLayer =null;


export const drawCanvas = {
    init:(width=localStorage.getItem('canvasWidth'),height=localStorage.getItem('canvasHeight'))=>{
        if(stage){
            console.log("已经存在stage对象")
            return false;
        }
        console.log("canvas init 初始化");
        stage= new Stage({
            container: 'editCanvas', 
            width: width,
            height: height
        });

        // guidLayer = drawGuid(width,height);
        // stage.add(guidLayer);
        
        drawGuid.init(width,height,stage);

        drawTempRect.init(width,height,stage);
        drawLine.init(width,height,stage);
        drawRect.init(width,height,stage);
        drawPaddingRuler.init(width,height,stage);
       

        drawRectingLayer = drawRecting(width,height,stage); //绘制中的矩形
        stage.add(drawRectingLayer);

        
        localStorage.setItem("canvasWidth",width);
        localStorage.setItem("canvasHeight",height);

        return stage;
    },
    reset:(width=localStorage.getItem('canvasWidth')*1,height=localStorage.getItem('canvasHeight')*1)=>{
        
        console.log("reset 更新stage")
        stage.setAttrs({ width: width, height: height});
        localStorage.setItem("canvasWidth",width);
        localStorage.setItem("canvasHeight",height);

        // guidLayer.destroy();
        // guidLayer = drawGuid(width,height);
        // stage.add(guidLayer);
        drawGuid.update();
        drawPaddingRuler.update();
        drawRect.update();
        drawLine.update();
       

        //窗口有变化，清空临时展位
        drawTempRect.removeAll();
        store.commit("setTempListsRemoveAll",[]);
        // message.warning('检测到浏览器窗口发生改变，临时展位将被清空！');
            
        stage.draw();
    },
    updateTempRect:(width=localStorage.getItem('canvasWidth'),height=localStorage.getItem('canvasHeight'))=>{
            drawRect.update();
            drawTempRect.update();
            store.commit("setDrawTempListsRemoveAll",[]);  //清空已经画过的临时展位
    },
    removeTempRect:()=>{
        drawTempRect.removeAll();
        stage.draw();
    },
    updateGuid:(width=localStorage.getItem('canvasWidth'),height=localStorage.getItem('canvasHeight'))=>{
        // guidLayer.destroy();
        // guidLayer = drawGuid(width,height);
        // stage.add(guidLayer);
        drawGuid.update();
        stage.draw();
    },
    refresh:(width=localStorage.getItem('canvasWidth'),height=localStorage.getItem('canvasHeight'))=>{
        drawRect.update();
        stage.draw();
    },
    refreshLine:(width=localStorage.getItem('canvasWidth'),height=localStorage.getItem('canvasHeight'))=>{
        drawLine.update();
        stage.draw();
    }
}
