<template>
  <a-modal :zIndex="9" title="已售展位调整更新日志" v-model:visible="show" width="1000px" height="80vh" @cancel="close" :destroyOnClose="false"  :footer="null">
      <div class="logbox">

        <div class="log-list">
          <a-empty v-if="isload&&list.length===0" :image="simpleImage" />
          <ul>
            <li v-for="(item) in list" :key="item.message_id">
              <div>
                <span>{{item.member_name}}</span>
                <span>{{item.create_time}}</span>
              </div>
              <div>{{item.content}}</div>
            </li>
            
          </ul>

        </div>
        
        <div class="log-form">
          <textarea v-model="formdata.content" placeholder="比如：A108展位已经签合同卖出，后续调整了位置，独立岛变成了背靠背展位。"></textarea>
          <a-button type="primary" @click="submit()">添加日志</a-button>
        </div>

      </div>
    </a-modal>
</template>

<script>
import { ExclamationCircleOutlined,DeleteOutlined,CloudUploadOutlined,ClearOutlined } from '@ant-design/icons-vue';
import { createVNode, ref, toRefs, watch, reactive,computed, onMounted } from 'vue'
import {useStore} from 'vuex'
import { Modal,Empty,message } from 'ant-design-vue';
import {createMessage,getMessage} from '@/api/'


  export default {
    components:{DeleteOutlined, CloudUploadOutlined,ClearOutlined},
    props:{
      datas:{type:Object,default:()=>{}},
      logVisible:{type:Boolean, default:false}
    },
    setup(props,{emit}){
      const show = ref(false);
      const store = useStore();
      const list = ref([]);
      const isload = ref(false);

      const formdata = reactive({
        token:localStorage.getItem('mtoken'),
        showroom_cid:'',
        content:''
      })

      console.log(formdata)

      // show.value = props.logVisible;

      const close = ()=>{
        show.value = false;
        emit('update:logVisible', false)
      }

      //初始化
      watch(props,function(newValue, oldValue){
        show.value = newValue.logVisible;

        if(newValue.logVisible){
          formdata.showroom_cid = store.state.selectCategory.id;
          getList();
        }
      })

      const getList = ()=>{
        let token = localStorage.getItem('mtoken');
        getMessage({token:token,showroom_cid:formdata.showroom_cid}).then(res=>{
          console.log(res);
          isload.value = true;
          list.value = res;
        })
      }

      const submit = ()=>{
        console.log(formdata)
        if(formdata.content===""){
          message.info('请输入日志内容！');
          return false;
        }
        createMessage(formdata).then(res=>{
          console.log(res);
          if(res.errcode===200){
            message.success('日志添加成功！');
            formdata.content = '';
            getList();
          }
        })
      }


      return {
        show,
        close,
        formdata,
        submit,
        simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
        list,
        isload
      }
    }
  }
</script>

<style scoped>
.el-dialog .el-dialog__body{padding:10px 20px 30px 20px;}

.logbox{width:1000px; height:calc( 100vh - 300px);  margin-top:-20px;}


.log-list{width:954px; height:calc( 100vh - 300px - 140px ); overflow-y: auto;}


.log-form{ border-top:1px solid #ddd; height:120px; width: 952px;}
.log-form textarea{width:924px; height:70px; padding:10px; margin:20px 10px 10px 10px; resize: none; border-radius: 5px;}
.log-form button{display: block; margin:0 auto;}

.btnbox>button{margin:0 5px;}

ul li{border-bottom:1px dashed #ddd; padding:8px 0; margin:0 8px 5px 8px;}
ul li:nth-last-child(1){border:none;}
ul li div:nth-of-type(1){}
ul li div:nth-of-type(1) span:nth-of-type(1){font-size:14px; color:#111; font-weight: bold;}
ul li div:nth-of-type(1) span:nth-of-type(2){font-size:13px; color:#888; margin-left:5px;}
ul li div:nth-of-type(2){font-size:14px; margin-top:5px; color:#666; line-height: 22px; background:#f1f1f1; padding:3px 5px; border-radius: 5px;}


</style>

<style>
  .ant-modal-header{border-bottom:none;}
  .full-modal .ant-modal {
    max-width: 100%;
    top: 0;
    padding-bottom: 0;
    margin: 0;
  }
  .full-modal .ant-modal-content {
    display: flex;
    flex-direction: column;
    height: calc(100vh);
  }
 .full-modal  .ant-modal-body {
    flex: 1;
  }
</style>