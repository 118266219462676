import { createVNode, computed} from 'vue'

import {Stage, Layer, Group, Text} from 'konva';
// import {drawRuler} from './ruler.js'
import {usekeyBoard, useSetDragAlign} from '@/use/diy-use.js'
import store from '@/store/';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { Modal } from 'ant-design-vue';
import {removeRoom} from "@/api/index.js";

let rectLayer = null

export const drawRect = {
    init:(width=localStorage.getItem('canvasWidth')*1,height=localStorage.getItem('canvasHeight')*1,stage)=>{
        rectLayer = new Layer();

        stage.find('.cloudrect').each((tr)=>{
            tr.destroy();
            stage.batchDraw();
        })

        drawRect.draw(rectLayer,stage);
        stage.add(rectLayer)
    },
    draw:(layer=rectLayer,stage)=>{
    // const rulerIsShow = computed(() => store.state.rulerIsShow);
        let width=localStorage.getItem('canvasWidth')*1;
        let height=localStorage.getItem('canvasHeight')*1;
        const selectCategory = computed(() => store.state.selectCategory);
        const lists = computed(() => store.state.lists);
        const radio = width/selectCategory.value.width;
        // const guidWidth = store.state.guidWidth;
        let selectGrout = null;

        var tween;

        // stage.find('.cloudrect').each((group)=>{
        //     group.destroy();
        //     stage.batchDraw();
        // })

        lists.value.map((rect)=>{
            // console.log("rect: ",rect)
            //矩形在页面显示的宽高
            const vWidth = rect.width*radio;
            const vHeight = rect.height*radio;
            
            //创建一个分组
            let group = new Group({
                x: rect.left*radio+0.5,
                y: rect.top*radio+0.5,
                width: vWidth,
                height: vHeight,
                type:'status'+rect.status,
                name:"cloudrect",
                draggable: true,
                dragBoundFunc:(pos)=>{
                    let x = pos.x;
                    let y = pos.y;
                    if(x<=0){
                        x =0;
                    }
                    if(x>=width-group.attrs.width){
                        x =width-group.attrs.width;
                    }
                    if(y<=0){
                        y =0;
                    }
                    if(y>=height-group.attrs.height){
                        y =height-group.attrs.height;
                    }
                    return {
                        x:x,
                        y:y
                    }
                }
            });

            group.on('mousedown', function () {
                selectGrout = group;
                store.commit("setSelectGroup",selectGrout);
                // console.log("拖到开始",rect.left)
            });
            group.on('mouseover', function () {
                // if (tween) {tween.destroy();}
                document.body.style.cursor = 'move';
                const selectIcon= layer.find("#icon"+rect.id)[0];
                const editIcon= layer.find("#icon"+rect.id)[1];
                // if(selectIcon){
                //     tween = new Konva.Tween({
                //         node: selectIcon,
                //         duration: 0.3,
                //         opacity:0.7,
                //     }).play();
                // }
                if(selectIcon){
                    selectIcon.opacity(0.5);
                }
                if(editIcon){
                    editIcon.opacity(0.5);  
                }
                layer.draw();
            });
            group.on('mouseout', function () {
                document.body.style.cursor = 'default';
                if (tween) {tween.destroy();}
                const selectIcon= layer.find("#icon"+rect.id)[0];
                const editIcon= layer.find("#icon"+rect.id)[1];
                if(selectIcon){
                    selectIcon.opacity(0.1);
                }
                if(editIcon){
                    editIcon.opacity(0.1);
                }
                layer.draw();
            });
            group.on('dragend', function(e) {
                useSetDragAlign(e.target, radio);
                let sg = e.target;
                let newRect = {width:Math.round(sg.attrs.width/radio), height: Math.round(sg.attrs.height/radio), left:Math.round(sg.attrs.x/radio), top: Math.round(sg.attrs.y/radio)};
                console.log(newRect);

                setTimeout(()=>{
                    store.commit("setEditPopupIsShow",true);
                    store.commit("setEditPopupData",Object.assign({},rect,newRect));
                },300)
            });
            group.on('dragmove',(e)=>{
                let x = e.target.attrs.x;
                let y = e.target.attrs.y;
                if(x<=0){
                    return false;
                }
            })

            const {status, title } = rect;
            let fillStyle = '#fff';
            let opacity = 0.8;
            if(status===2){
                //已出售
                fillStyle="#ffadad";
                opacity = 0.7;
                //禁止移动
                // group.draggable = false;
                // group.setAttrs({draggable : false})

            }else if(status===3){
                //锁定中
                fillStyle="#eee";
                opacity = 0.7;
                //禁止移动
                // group.draggable = false;
                // group.setAttrs({draggable : false})
            }
            //画矩形
            var box = new Konva.Rect({
                x: 0, y: 0,
                width: rect.width*radio,
                height: rect.height*radio,
                fill: fillStyle,
                stroke: '#000',
                strokeWidth: 0,
                opacity:opacity
            });
            group.add(box);
            //画矩形四条边
            let line1 = new Konva.Line({
                x: 0, y: 0,
                opacity:rect.top_line===1?1:0.5,
                points: [0,0,0+rect.width*radio, 0],
                stroke: rect.top_line===1?"#111":"#111",
                strokeWidth: 1,
                dash:rect.top_line===1?[2,3]:[]
            });
            let line2 = new Konva.Line({
                x: 0, y: 0,
                opacity:rect.right_line===1?1:0.5,
                points: [rect.width*radio, 0, rect.width*radio, rect.height*radio],
                stroke: rect.right_line===1?"#111":"#111",
                strokeWidth: 1,
                dash:rect.right_line===1?[2,3]:[]
            });
            let line3 = new Konva.Line({
                x: 0, y: 0,
                opacity:rect.bottom_line===1?1:0.5,
                points: [0, rect.height*radio, rect.width*radio, rect.height*radio],
                stroke: rect.bottom_line===1?"#111":"#111",
                strokeWidth: 1,
                dash:rect.bottom_line===1?[2,3]:[]
            });
            let line4 = new Konva.Line({
                x: 0, y: 0,
                opacity:rect.left_line===1?1:0.5,
                points: [0, 0, 0, rect.height*radio],
                stroke: rect.left_line===1?"#111":"#111",
                strokeWidth: 1,
                dash:rect.left_line===1?[2,3]:[]
            });
            group.add(line1);
            group.add(line2);
            group.add(line3);
            group.add(line4);


            let titleText,areaText;
            // 画展位号
            titleText = new Text({
                x:2, y:2,
                width: vWidth,
                text:title,
                fill:'#666', fontSize:12
            })
            if(rect.width===3000){
                titleText.fontSize(10);
            }
            // 画尺寸
            areaText = new Text({
                x:2, y:vHeight-12,
                width: vWidth-4,
                // text:`${Math.floor(rect.width/1000)}×${Math.floor(rect.height/1000)}=${Math.floor(rect.width*rect.height/1000000)}`,
                text:`${rect.width/1000}×${rect.height/1000}=${rect.width*rect.height/1000000}`,
                fill:'#666',fontSize:12,
                align:'right', verticalAlign:'middle'
            })
            
        
            // rect.width>3000 && group.add(titleText);
            group.add(titleText);

            rect.width>3000 && group.add(areaText);
            //画展位标尺
            // rulerIsShow.value && drawRuler(rect,width,height,group);


            let iconWidth = rect.width>6000 ? 24 : 14;  //操作按钮宽度
            // let iconM = rect.width>6000 ? 10 : 2;  //操作按钮间距
            //画操作相关功能，如查看详情，购买展位等
            let imageObj = new Image();
            let delicon;
            imageObj.onload = function() {
                delicon = new Konva.Image({
                    x: rect.width*radio/2-iconWidth/2-iconWidth/1.5,
                    y: rect.height*radio/2-iconWidth/2,
                    image: imageObj,
                    width: iconWidth,
                    height: iconWidth,
                    opacity:0.1,
                    name:'icon'+rect.status,
                    id:"icon"+rect.id
                });
                // rect.status!==2 && group.add(delicon);
                group.add(delicon);
                // if(rect.width===3000||rect.height===3000){
                //     delicon.setAttrs({width:18,height:18,x: rect.width*radio/2-9, y: rect.height*radio/2-9})
                // }
                //删除展位
                delicon.on('click' , (e)=>{
                    console.log(e);
                    Modal.confirm({
                        title: '删除展位?',
                        icon: createVNode(ExclamationCircleOutlined),
                        content: "展位删除不可恢复，请确认！",
                        okText: '删除',
                        cancelText:"取消",
                        onOk:() => {
                            console.log(rect);
                            removeRoom({token:localStorage.getItem('mtoken'), category_id:rect.category_id, showroom_id:rect.id}).then(res=>{
                                store.dispatch("getLists",selectCategory.value.id);
                                e.target.findAncestors('Group')[0].remove();
                                layer.batchDraw();
                            });
                        }
                    });
                })
                delicon.on('mousemove', function () {
                    document.body.style.cursor = 'pointer';
                });
            };
            if(rect.status===1){
                imageObj.src = require('@/assets/icon/del.png');
            }
            //画编辑相关功能
            let imageObj2 = new Image();
            let editicon;
            imageObj2.onload = function() {
                editicon = new Konva.Image({
                    x: rect.width*radio/2-iconWidth/2+iconWidth/1.5,
                    y: rect.height*radio/2-iconWidth/2,
                    image: imageObj2,
                    width: iconWidth,
                    height: iconWidth,
                    opacity:0.1,
                    name:'icon'+rect.status,
                    id:"icon"+rect.id
                });
                group.add(editicon);
                layer.draw();
                // if(rect.width===3000||rect.height===3000){
                //     editicon.setAttrs({width:18,height:18,x: rect.width*radio/2-9, y: rect.height*radio/2-9})
                // }
                //删除展位
                editicon.on('click' , (e)=>{
                    console.log(e);
                    setTimeout(()=>{
                        store.commit("setEditPopupIsShow",true);
                        store.commit("setEditPopupData",Object.assign({},rect));
                    },300)
                })
                editicon.on('mousemove', function () {
                    document.body.style.cursor = 'pointer';
                });
                if(rect.status!==1){
                    editicon.setAttrs({x:rect.width*radio/2-iconWidth/2,y:rect.height*radio/2-iconWidth/2})
                }
            };
            imageObj2.src = require('@/assets/icon/edit.png');
            layer.add(group);
        })

        // 添加键盘事件
        // usekeyBoard(stage,layer,radio);



        //添加键盘事件  该方法已经移动到diy-use.js中
        // var container = stage.container();
        // container.tabIndex = 1;
        // container.focus();
        // const DELTA = guidWidth*radio;
        // container.addEventListener('keydown', function(e) {
        //     console.log(e.keyCode);
        //     if (e.keyCode === 37 || e.keyCode ===65) {
        //         (selectGrout.x() - DELTA)>0 && selectGrout.x(selectGrout.x() - DELTA);
        //     } else if (e.keyCode === 38 || e.keyCode ===87) {
        //         (selectGrout.y() - DELTA)>0 && selectGrout.y(selectGrout.y() - DELTA);
        //     } else if (e.keyCode === 39 || e.keyCode === 68) {
        //         selectGrout.x(selectGrout.x() + DELTA);
        //     } else if (e.keyCode === 40 || e.keyCode === 83) {
        //         selectGrout.y(selectGrout.y() + DELTA);
        //     }else if (e.keyCode === 46) {
        //         //删除
        //     } else {
        //       return;
        //     }
        //     e.preventDefault();
        //     layer.batchDraw();
        // });
    
        return layer
    },
    update:()=>{
        drawRect.removeAll();
        drawRect.draw(rectLayer)
    },
    removeAll:()=>{
        rectLayer && rectLayer.destroyChildren();
    }
}