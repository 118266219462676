<template>
  <div>
    <a-modal :zIndex="1001"  title="新增展位" v-model:visible="addPopupIsShow"  @cancel="handleCancel" width="700px" :footer="null">
        <a-form ref="formRef" :model="formState" :rules="rules" :label-col="{span: 4}" :wrapper-col="{span: 18}">
          
            <a-form-item label="编号" name="title">
                <a-input v-model:value="formState.title" />
            </a-form-item>
            <a-form-item label="尺寸" name="width">
                <div class="display:flex;">
                  <a-input style="width: 234px" v-model:value="formState.width" prefix="宽:" suffix="mm" :disabled="isDisabled" />
                  <span> × </span>
                  <a-input style="width: 234px" v-model:value="formState.height" prefix="高:" suffix="mm" :disabled="isDisabled" />
                </div>
            </a-form-item>
            <!-- <a-form-item label="高" name="height">
                <a-input v-model:value="formState.height" :disabled="isDisabled" />
            </a-form-item> -->
            <a-form-item label="位置" name="width">
              <div class="display:flex;">
                <a-input style="width: 234px" v-model:value="formState.top" prefix="上边距:" suffix="mm" :disabled="isDisabled" />
                <span> × </span>
                <a-input style="width: 234px" v-model:value="formState.left" prefix="左边距:" suffix="mm" :disabled="isDisabled" />
              </div>
            </a-form-item>

            <!-- <a-form-item ref="top" label="上边距" name="top">
                <a-input v-model:value="formState.top" :disabled="isDisabled" />
            </a-form-item>
            <a-form-item  label="左边距" name="left">
                <a-input v-model:value="formState.left" :disabled="isDisabled" />
            </a-form-item> -->

            <a-form-item  label="边框类型" name="left" class="rect-box">
              <a-radio-group class="top" name="radioGroup" v-model:value="boothBorder[0]">
                <a-radio :value="1">虚线</a-radio>
                <a-radio :value="0">实线</a-radio>
              </a-radio-group>
              <a-radio-group class="right" name="radioGroup" v-model:value="boothBorder[1]">
                <a-radio :value="1">虚线</a-radio>
                <a-radio :value="0">实线</a-radio>
              </a-radio-group>
              <a-radio-group class="bottom" name="radioGroup" v-model:value="boothBorder[2]">
                <a-radio :value="1">虚线</a-radio>
                <a-radio :value="0">实线</a-radio>
              </a-radio-group>
              <a-radio-group class="left" name="radioGroup" v-model:value="boothBorder[3]">
                <a-radio :value="1">虚线</a-radio>
                <a-radio :value="0">实线</a-radio>
              </a-radio-group>
              <div class="rect" :style="rectStyle"></div>
            </a-form-item>

            <a-form-item label="日志" name="content">
              <a-input v-model:value="content" placeholder="选填，内容会更新到展馆日志！" />
            </a-form-item>

        </a-form>
        <div class="dialog-footer">
        
          <span class="wt" @click="isDisabled=!isDisabled" title="展位尺寸精度毫米级别调整">展位尺寸微调</span>
          <a-button type="primary" @click="sub()" :loading="confirmLoading"><PlusCircleOutlined v-if="!confirmLoading" />确定</a-button>
        
        </div>
    </a-modal>
  </div>
</template>
<script>
import { ref, reactive, defineComponent, onMounted,computed, watch, toRaw } from 'vue';
import {useStore} from 'vuex'
import {createRoom,createMessage} from "@/api/index.js";
import {PlusCircleOutlined} from '@ant-design/icons-vue';
// import {message} from 'ant-design-vue';
import {stage} from './index.js'; 

export default defineComponent({
  components:{PlusCircleOutlined},
  setup(props,{emit}) {
    const store = useStore();
    const addPopupIsShow = computed(()=>store.state.addPopupIsShow);
    const addPopupData =computed(()=>store.state.addPopupData);
    const selectCategory = computed(()=>store.state.selectCategory);
    const selectGroup = computed(()=>store.state.selectGroup);
    const serveRectLists = computed(()=>store.state.lists);
    const tempLists = computed(()=>store.state.tempLists);


    const isDisabled = ref(true)
    const boothBorder = ref([1,1,1,1]);

    const confirmLoading = ref(false);
    const formRef = ref();
    const formState = reactive({
      token:localStorage.getItem('mtoken'),
      category_id:selectCategory.value.id,
      parent_id:'0',
      title:'',
      width:'',
      height:'',
      top:'',
      left:'',
      status:'1',
      top_line: '1',
      right_line: '1',
      bottom_line: '1',
      left_line: '1'
    });
    //日志类容
    const content = ref("");


    const rules = {
       title: [{ required: true, message: '请输入展位编号', trigger: 'blur' }],
       //  width: [{ required: true, message: '请输入展位宽', trigger: 'blur' }],
    };

   
    const handleCancel = ()=>{
      console.log("关闭弹出层")
      // formRef.value.resetFields();
      store.commit("setAddPopupIsShow",false);
    }

    const rectStyle = computed(()=>{
      let lineType = ['solid','dotted'];
      return {
        borderTop:`2px ${lineType[boothBorder.value[0]]} #111`, 
        borderRight:`2px ${lineType[boothBorder.value[1]]} #111`,
        borderBottom:`2px ${lineType[boothBorder.value[2]]} #111`,
        borderLeft:`2px ${lineType[boothBorder.value[3]]} #111`
      }
    })

    // 检测两条线段是否有重合，在x轴或y轴, 当检查横向线段时候，x1，x2，代表线段横向两端x坐标，当检查纵向线段时候，x1，x2代表纵向线段两端的y坐标
    const checkLineCross = (line1,line2)=>{
        if(line2.x2>line1.x2 && line2.x1<=line1.x1){
          return true;
        }
        if(line2.x2<=line1.x2 && line2.x1>=line1.x1){
          return true;
        }
        if(line2.x2<=line1.x2 && line2.x2>line1.x1){
          return true;
        }
        if(line2.x1>=line1.x1 && line1.x2>line2.x1){
          return true;
        }
      }


    //兼容弹出层状态，状态变化时候更新表单内容
    watch(addPopupIsShow, function(news,old){
      if(!news){
        return false;
      }

      console.log("selectCategory",selectCategory.value)
      // console.log("store selectCategory", store.state.selectCategory)
      console.log("store selectGroup", store.state.selectGroup)
      console.log("store serveRectLists", store.state.lists)
      console.log('watch 弹出层状态发生变化');
      console.log(addPopupData.value);
      const rdata = addPopupData.value;
      formState.category_id = store.state.selectCategory.id;
      formState.width = rdata.width;
      formState.height = rdata.height;
      formState.left = rdata.left;
      formState.top = rdata.top;

      boothBorder.value = [1,1,1,1];

      // 检测展位周围是否有展位相邻
      const {top,left,width,height} = formState;
      const concatArr = tempLists.value.concat(serveRectLists.value);
      console.log(concatArr)

      concatArr.forEach((item)=>{
        console.log("item",item)
        //检测上方有没相邻展位
        if((top===item.top+item.height)&&checkLineCross({x1:left,x2:left+width},{x1:item.left,x2:item.left+item.width})){
          boothBorder.value[0] = 0;
        }
        //检测下方有没相邻展位
        if((top+height===item.top)&&checkLineCross({x1:left,x2:left+width},{x1:item.left,x2:item.left+item.width})){
          boothBorder.value[2] = 0;
        }
        //检测左侧有没有相邻展位
        if((left===item.left+item.width)&&checkLineCross({x1:top,x2:top+height},{x1:item.top,x2:item.top+item.height})){
          boothBorder.value[3] = 0;
        }
        //检测右侧有没有相邻展位
        if((left+width===item.left)&&checkLineCross({x1:top,x2:top+height},{x1:item.top,x2:item.top+item.height})){
          boothBorder.value[1] = 0;
        }
      })
    })


    const sub = ()=>{
      formRef.value
        .validate()
        .then(() => {
          confirmLoading.value=true;
          console.log('values', formState, toRaw(formState));
          //给边框线段类型赋值
          formState.top_line = boothBorder.value[0];
          formState.right_line = boothBorder.value[1];
          formState.bottom_line = boothBorder.value[2];
          formState.left_line = boothBorder.value[3];
          createRoom(toRaw(formState)).then(res=>{
            if(res.errcode===200){
              store.dispatch("getLists",selectCategory.value.id);
              let sg = toRaw(selectGroup.value);
              sg.remove();
              if(content.value!==''){
                createMessage({token:localStorage.getItem('mtoken'),showroom_cid:selectCategory.value.id,content:content.value}).then(res=>{
                  if(res.errcode===200){
                    content.value = '';
                  }
                })
              }
              setTimeout(()=>{
                confirmLoading.value=false;
                formRef.value.resetFields();
                store.commit("setAddPopupIsShow",false);
                store.commit("setTempListsRemove",store.state.addPopupData);
                stage.batchDraw();
              },1000)
            }else{
              confirmLoading.value=false;
              alert(res.errmsg)
            }
          })
          
        })
        .catch(error => {
          console.log('error', error);
        });
    }


    return {
      addPopupIsShow,
      confirmLoading,
      handleCancel,
      formRef,
      other: '',
      formState,
      rules,
      sub,
      isDisabled,
      boothBorder,
      rectStyle,
      content
    };
  },
});
</script>

<style scoped>
.dialog-footer{text-align: right;}
.dialog-footer button{margin-left:10px;}
.dialog-footer .wt{font-size:13px; margin-right:10px; color:#666; text-decoration: underline; cursor: pointer; user-select: none;}

.rect-box{position: relative; width:652px; height:200px;}
.rect-box .rect{width:200px; height:120px; background:#f1f1f1; position: absolute; top:50px; left:50%; margin-left:-100px;}
.rect-box .top{position: absolute; top:20px; left:50%; margin-left:-70px;}
.rect-box .bottom{position: absolute; top:180px; left:50%; margin-left:-70px;}
.rect-box .left{position: absolute; top:100px; left:10px; width:140px;}
.rect-box .right{position: absolute; top:100px; left:355px; width:140px;}


</style>