import { ref, reactive, toRefs, computed} from 'vue'

import {Stage, Layer, Group, Text} from 'konva';
import {drawRuler} from './ruler.js'
import store from '@/store/';

let rectLayer = null

export const drawRect = {
    init:(width=localStorage.getItem('canvasWidth')*1,height=localStorage.getItem('canvasHeight')*1,stage)=>{
        rectLayer = new Layer();

        stage.find('.indexRect').each((tr)=>{
            tr.destroy();
            stage.batchDraw();
        })

        drawRect.draw(rectLayer,width,height);
        stage.add(rectLayer)
    },
    draw:(layer,width,height)=>{
        //表单状态
        const isAdmin = computed(()=>(store.state.user.role_id===1||store.state.user.role_id===2));
        // const isSell = computed(()=>store.state.user.role_id===3);
        // const isCaiwu = computed(()=>store.state.user.role_id===2);
        const rulerIsShow = computed(() => store.state.rulerIsShow);
        const selectCategory = computed(() => store.state.selectCategory);
        const lists = computed(() => store.state.lists);
        // const layer = new Layer();
        const radio = width/selectCategory.value.width;

        var tween;

        lists.value.map((rect)=>{
            // console.log(rect)
            //矩形在页面显示的宽高
            const vWidth = rect.width*radio;
            const vHeight = rect.height*radio;
            let icon;
            //创建一个分组
            let group = new Group({
                x: rect.left*radio+0.5,
                y: rect.top*radio+0.5,
                name:'indexRect',
                width: vWidth,
                height: vHeight
            });
            group.on('mouseover', function () {
                if (tween) {tween.destroy();}
                const selectIcon= layer.find("#icon"+rect.id)[0];
                tween = new Konva.Tween({
                    node: selectIcon,
                    duration: 0.3,
                    opacity:0.7,
                }).play();
                if(rect.height<=8000||rect.width<=8000){
                    titleText.opacity(0.2);
                    companyText && companyText.opacity(0.2);
                    areaText.opacity(0.2);
                }
                // selectIcon.opacity(0.7);
                // layer.draw();
            });
            group.on('mouseout', function (e) {
                if (tween) {tween.destroy();}
                const selectIcon= layer.find("#icon"+rect.id)[0];
                selectIcon.opacity(0);
                if(rect.height<=8000||rect.width<=8000){
                    titleText.opacity(1);
                    companyText && companyText.opacity(1);
                    areaText.opacity(1);
                }
                layer.draw();
            });
            // group.on('dragmove',(e)=>{
            //     let x = e.target.attrs.x;
            //     let y = e.target.attrs.y;
            //     if(x<=0){
            //         return false;
            //     }
            // })
            const {status, title } = rect;
            let fillStyle = '#fff';
            if(status===2){
                fillStyle="#ffadad";
            }else if(status===3){
                fillStyle="#ddd";
            }else if(status===4){
                fillStyle="#bcffbc";
            }

            //画矩形
            var box = new Konva.Rect({
                x: 0, y: 0,
                width: rect.width*radio,
                height: rect.height*radio,
                fill: fillStyle,
                stroke: '#ed1f24',
                strokeWidth: 0
            });
            group.add(box);
            //画矩形四条边
            let line1 = new Konva.Line({
                x: 0, y: 0,
                opacity:rect.top_line===1?1:0.8,
                points: [0,0,0+rect.width*radio, 0],
                stroke: rect.top_line===1?"#ed1f24":"#ed1f24",
                strokeWidth: 1.5,
                dash:rect.top_line===1?[2,3]:[]
            });
            let line2 = new Konva.Line({
                x: 0, y: 0,
                opacity:rect.right_line===1?1:0.8,
                points: [rect.width*radio, 0, rect.width*radio, rect.height*radio],
                stroke: rect.right_line===1?"#ed1f24":"#ed1f24",
                strokeWidth: 1.5,
                dash:rect.right_line===1?[2,3]:[]
            });
            let line3 = new Konva.Line({
                x: 0, y: 0,
                opacity:rect.bottom_line===1?1:0.8,
                points: [0, rect.height*radio, rect.width*radio, rect.height*radio],
                stroke: rect.bottom_line===1?"#ed1f24":"#ed1f24",
                strokeWidth: 1.5,
                dash:rect.bottom_line===1?[2,3]:[]
            });
            let line4 = new Konva.Line({
                x: 0, y: 0,
                opacity:rect.left_line===1?1:0.8,
                points: [0, 0, 0, rect.height*radio],
                stroke: rect.left_line===1?"#ed1f24":"#ed1f24",
                strokeWidth: 1.5,
                dash:rect.left_line===1?[2,3]:[]
            });
            group.add(line1);
            group.add(line2);
            group.add(line3);
            group.add(line4);


            let offset = 10;
            let companyText,titleText,areaText,minTitleText;
            let fontSize = rect.width>6000 ? 20 :10;
            // 画参展品牌
            // if(isAdmin.value&&rect.status &&!!rect.order_form.customer_brand){
            if(rect.status &&!!rect.order_form.customer_brand){
                offset = 20;
                companyText = new Text({
                    x:0, y:vHeight/2,
                    width: vWidth,
                    text:rect.order_form.customer_brand.substr(0,8),
                    fill:'#666',fontSize:14, wrap:"none", ellipsis:true,
                    align:'center', verticalAlign:'middle'
                })
                group.add(companyText);
            }
            // console.log("画布高度：",height)
            //根据浏览器高度，动态设置标题字体大小
            let titleFontSize = 20;
            if(height<541){
                titleFontSize = height/30
            }
            // 画展位号+公司名
            titleText = new Text({
                x:0, y:vHeight/2-offset-5,
                width: vWidth,
                text:title,
                fill:'#333', fontSize:titleFontSize, fontStyle:'bold',
                align:'center', verticalAlign:'middle'
            })
            // 画尺寸
            areaText = new Text({
                x:0, y:vHeight/2+offset+2,
                width: vWidth,
                // text:`${Math.floor(rect.width/1000)}×${Math.floor(rect.height/1000)}=${Math.floor(rect.width*rect.height/1000000)}`,
                text:`${rect.width/1000}×${rect.height/1000}=${rect.width*rect.height/1000000}`,
                fill:'#555',fontSize:12,
                align:'center', verticalAlign:'middle'
            })
            if(rect.height<=8000||rect.width<=8000){
                // areaText.visible(false);
                offset = 15;
                companyText && companyText.fontSize(10).y(vHeight/2);
                areaText.fontSize(10).y(vHeight/2+offset-4);
                if(companyText){
                    titleText.fontSize(12).y(vHeight/2-offset-5)
                areaText.fontSize(10).y(vHeight/2+offset-0);

                }else{
                    titleText.fontSize(12).y(vHeight/2-5)
                }
            }


            //标摊展位
            if(rect.height<=4000||rect.width<=4000){
                areaText.visible(false);
                if(companyText){
                    companyText && companyText.y(vHeight/2+6).fontSize(8)
                    titleText.y(vHeight/2-6).fontSize(9)
                }else{
                    titleText.y(vHeight/2-4).fontSize(10)
                }
            }

            group.add(titleText);
            group.add(areaText);
            //画展位标尺
            // if(selectCategory.value.code!==11&&selectCategory.value.code!==12){
                rulerIsShow.value && drawRuler(rect,width,height,group);
            // }


            //画操作相关功能，如查看详情，购买展位等
            let imageObj = new Image();
            imageObj.onload = function() {
                icon = new Konva.Image({
                    x: rect.width*radio-28,
                    y: 5,
                    image: imageObj,
                    width: 20,
                    height: 20,
                    opacity:0,
                    name:'icon'+rect.status,
                    id:"icon"+rect.id,
                    type:rect.status
                });
                if(rect.height<=8000||rect.width<=8000){
                    icon.setAttrs({x:(vWidth-20)/2,y:(vHeight-20)/2,width:20,height:20})
                }
                if(rect.height<=4000&&rect.width<=4000){
                    icon.setAttrs({x:(vWidth-16)/2,y:(vHeight-16)/2,width:16,height:16})
                }
            
                if(rect.status===1){
                    group.add(icon);
                }
                if(isAdmin.value&&(rect.status===2||rect.status===3)){
                    group.add(icon);
                }

                if(store.state.user.id === rect.member.id){
                    group.add(icon);
                }

                
                icon.on('click' , (e)=>{
                    store.commit("setBuyPopupIsShow",true);
                    store.commit("setBuyPopupData",rect);
                    console.log(rect)
                })

                icon.on('mouseover', function () {
                    document.body.style.cursor = 'pointer';
                });
                icon.on('mouseout', function () {
                    document.body.style.cursor = 'default';
                });
            };
            if(rect.status===1){
                imageObj.src = require('@/assets/icon/add.png');
            }
            if(rect.status===2){
                imageObj.src = require('@/assets/icon/eyes.png');
            }
            if(rect.status===3){
                imageObj.src = require('@/assets/icon/edit.png');
            }

            layer.add(group);
        })
        console.log("layer: ",layer)
        return layer
    },
    update:(width,height)=>{
        drawRect.removeAll();
        drawRect.draw(rectLayer,width,height)
    },
    removeAll:()=>{
        rectLayer && rectLayer.destroyChildren();
    }
}