import { ref, reactive, toRefs, computed} from 'vue'

import {Stage, Layer, Line} from 'konva';
// import {drawRuler} from './ruler.js'
import store from '@/store/';

export let guidLayer = null;
export const drawGuid = {
    init:(width=localStorage.getItem('canvasWidth')*1,height=localStorage.getItem('canvasHeight')*1,stage)=>{
        guidLayer = new Layer();

        // stage.find('.cloudline').each((tr)=>{
        //     tr.destroy();
        //     stage.batchDraw();
        // })

        drawGuid.draw(guidLayer,stage);
        stage.add(guidLayer)
    },
    draw:(layer=guidLayer,stage)=>{
        console.log("画网格线");
        let width=localStorage.getItem('canvasWidth')*1;
        let height=localStorage.getItem('canvasHeight')*1;

        const rulerIsShow = computed(() => store.state.rulerIsShow);
        const selectCategory = computed(() => store.state.selectCategory);
        const guidWidth = store.state.guidWidth;
        const radio = width/selectCategory.value.width;

        const sumWidth = selectCategory.value.width;
        const sumHeight = selectCategory.value.height;

        const top = selectCategory.value.top_padding;
        const bottom = selectCategory.value.bottom_padding;
        const left = selectCategory.value.left_padding;
        const right = selectCategory.value.right_padding;

        // let guidWidth = 1000; //格子宽度 默认为1m
        let w = sumWidth - left%guidWidth;
        let h = sumHeight - top%guidWidth;
        let margin = guidWidth*radio;

        const org = {x:left%guidWidth*radio, y:top%guidWidth*radio};
        // const org = {x:left*radio, y:top*radio};

        console.log(org);

        // //纵向线 //超出边距外的不画网格线
        // for(let i = 0; i <= w/guidWidth; i++){
        //     //超出边距外的不画网格线
        //     if(org.x+i*margin>left*radio && (org.x+i*margin)<(sumWidth-right)*radio){
        //         layer.add(new Line({
        //             points: [org.x+i*margin, top*radio, org.x+i*margin, (sumHeight-bottom)*radio],
        //             stroke: '#666',
        //             strokeWidth: 0.5,
        //             dash: [3, 1],
        //             opacity:.15
        //         }))
        //     }
        // }
        // //横向线
        // for(let j = 0; j <= h/guidWidth; j++){
        //     //超出边距外的不画网格线
        //     if(org.y+j*margin>top*radio && (org.y+j*margin)<(sumHeight-bottom)*radio){
        //         layer.add(new Line({
        //             points: [left*radio, org.y+j*margin, (sumWidth-right)*radio, org.y+j*margin],
        //             stroke: '#666',
        //             strokeWidth: 0.5,
        //             dash: [3, 1],
        //             opacity:.15
        //         }))
        //     }   
        // }

        //纵向线
        for(let i = 0; i <= w/guidWidth; i++){
            layer.add(new Line({
                points: [org.x+i*margin, org.y - top%guidWidth*radio, org.x+i*margin, org.y+sumHeight*radio- top%1000*radio],
                stroke: '#666',
                strokeWidth: 0.5,
                dash: [3, 1],
                opacity:.15
            }))
        }
        //横向线
        for(let j = 0; j <= h/guidWidth; j++){
            layer.add(new Line({
                points: [org.x - left%guidWidth*radio, org.y+j*margin, org.x+sumWidth*radio-left%guidWidth*radio, org.y+j*margin],
                stroke: '#666',
                strokeWidth: 0.5,
                dash: [3, 1],
                opacity:.15
            }))
        }

        //画横向中线
        layer.add(new Line({
            points:[(w-right%guidWidth)*radio/2-1,0,(w-right%guidWidth)*radio/2-1,3],
            stroke: '#000',
            strokeWidth: 2,
        }))
        //画纵向中线
        layer.add(new Line({
            points:[0,(h-bottom%guidWidth)*radio/2-1, 3, (h-bottom%guidWidth)*radio/2-1],
            stroke: '#000',
            strokeWidth: 2,
        }))

        //画边距分割线
        //上下
        layer.add(new Line({
            points:[left*radio,top*radio, (sumWidth-right)*radio,top*radio],
            stroke: '#999',
            strokeWidth: 0.5,
        }))
        layer.add(new Line({
            points:[left*radio,(sumHeight-bottom)*radio+1.5, (sumWidth-right)*radio,(sumHeight-bottom)*radio+1.5],
            stroke: '#999',
            strokeWidth: 0.5,
        }))
        //左右
        layer.add(new Line({
            points:[left*radio,top*radio, left*radio, (sumHeight-bottom)*radio],
            stroke: '#999',
            strokeWidth: 0.5,
        }))

        layer.add(new Line({
            points:[(sumWidth-right)*radio-1,top*radio,  (sumWidth-right)*radio-1,(sumHeight-bottom)*radio],
            stroke: '#999',
            strokeWidth: 0.5,
        }))

        return layer
    },
    update:()=>{
        drawGuid.removeAll();
        drawGuid.draw(guidLayer)
    },
    removeAll:()=>{
        guidLayer.destroyChildren();
    }
}