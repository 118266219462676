<template>
  <div>
    <a-modal :title="formTitle" v-model:visible="buyPopupIsShow" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel" width="700px" :footer="null">
        <a-form ref="formRef" :model="formState" :rules="rules" :label-col="{span: 4}" :wrapper-col="{span: 18}">
            <a-form-item label="销售人员" name="member_id">
              <a-select placeholder="请选择销售人员" @change="saleChange" v-model:value="formState.member_name" :disabled="rectStatus===2">
                <a-select-option v-for="item in saleList" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
              </a-select>
            </a-form-item>
            
            <a-form-item label="公 司 名" name="company">
                <a-input v-model:value="formState.company" :disabled="rectStatus===2" />
            </a-form-item>
            <a-form-item label="参展品牌" name="customer_brand">
                <a-input v-model:value="formState.customer_brand" :disabled="rectStatus===2" placeholder="导出展位销售图，请输入参展品牌" />
            </a-form-item>
            <a-form-item label="展示内容" name="display_content">
                <a-input v-model:value="formState.display_content" :disabled="rectStatus===2" />
            </a-form-item>
            <a-form-item label="价格" name="price">
                <a-input v-model:value="formState.price" :disabled="rectStatus===2" />
            </a-form-item>
            <a-form-item ref="remarks" label="备注" name="remarks">
                <a-input v-model:value="formState.remarks" :disabled="rectStatus===2" />
            </a-form-item>
            <a-form-item ref="title" label="编号" name="title">
                <a-input v-model:value="formState.title" disabled />
            </a-form-item>
        </a-form>
        <div class="dialog-footer" v-if="rectStatus!==2">
          <a-button v-if="!isEdit&&(isCaiwu||isAdmin||isControl)" @click="sub(1)"><LockOutlined/>锁 定</a-button>
          <a-button v-if="!isEdit&&(isCaiwu||isAdmin||isControl)"  @click="sub(2)"><ShoppingOutlined />出 售</a-button>
          <a-button v-if="isEdit&&(isCaiwu||isAdmin||isControl)" @click="update(3)"><UnlockOutlined />取消锁定</a-button>
          <a-button v-if="isEdit&&(isCaiwu||isAdmin||isControl)" @click="update(1)"><SyncOutlined />更新</a-button>
          <a-button v-if="isEdit&&(isCaiwu||isAdmin||isControl)" @click="update(2)"><ShoppingOutlined />出 售</a-button>
          <a-button type="danger" @click="exportBooth()"><ExportOutlined />导出展位销售图</a-button>
        </div>
        <div class="dialog-footer" v-if="rectStatus===2">
          <a-button type="danger" @click="exportBooth()"><ExportOutlined />导出展位销售图</a-button>
          <!-- <a-button v-if="isEdit&&(isCaiwu||isAdmin)" @click="update(3)">清除出售状态</a-button> -->
          <a-button v-if="isEdit&&(isCaiwu||isAdmin)" @click="update(1)">改为锁定状态</a-button>

        </div>
        <!-- <div class="dialog-footer" v-if="rectStatus!==2">
          <a-button v-if="!isEdit&&!isCaiwu" @click="sub(1)"><LockOutlined/>锁 定</a-button>
          <a-button v-if="!isEdit"  @click="sub(2)"><ShoppingOutlined />出 售</a-button>
          <a-button v-if="isEdit&&!isSell&&!isCaiwu" @click="update(3)"><UnlockOutlined />取消锁定</a-button>
          <a-button v-if="isEdit&&!isCaiwu" @click="update(1)"><SyncOutlined />更新</a-button>
          <a-button v-if="isEdit&&!isSell" @click="update(2)"><ShoppingOutlined />出 售</a-button>
          <a-button type="danger" @click="exportBooth()"><ExportOutlined />导出展位销售图</a-button>
        </div>
        <div class="dialog-footer" v-if="rectStatus===2">
          <a-button type="danger" @click="exportBooth()"><ExportOutlined />导出展位销售图</a-button>
        </div> -->
    </a-modal>
  </div>
</template>
<script>
import { ref, reactive, defineComponent, onMounted,computed, watch, toRaw } from 'vue';
import {useStore} from 'vuex'
import {createOrder,updateOrder,getSaleList} from "@/api/index.js";
import {SyncOutlined,UnlockOutlined,LockOutlined,ShoppingOutlined,ExportOutlined} from '@ant-design/icons-vue';
import {message} from 'ant-design-vue';

export default defineComponent({
  components:{SyncOutlined,UnlockOutlined,LockOutlined,ShoppingOutlined,ExportOutlined},
  setup(props,{emit}) {
    const store = useStore();
    const buyPopupIsShow = computed(()=>store.state.buyPopupIsShow);
    const buyPopupData =computed(()=>store.state.buyPopupData);
    const selectCategory = computed(()=>store.state.selectCategory);
    const formTitle = ref("展位操作");
    const confirmLoading = ref(false);
    const rectStatus = ref(null); //1.空展位 2.已卖出的展位  3.锁定中的展位
    const saleList = ref([]); //销售人员列表
    const formRef = ref();
    const formState = reactive({
      token:localStorage.getItem('mtoken'),
      member_id:null,  //销售人员id
      member_name:null,
      showroom_id:'', //展位表id
      title:'',
      company:'',
      customer_brand:'',
      display_content:'',
      price:'',
      remarks:'',
      status:''
    });

    //表单状态
    const isEdit = ref(false);
    const isAdmin = computed(()=>store.state.user.role_id===1);
    const isControl = computed(()=>store.state.user.pavilion_title.split(',').indexOf(String(selectCategory.value.code))!==-1);
    const isSell = computed(()=>store.state.user.role_id===3);
    const isCaiwu = computed(()=>store.state.user.role_id===2);
    

    onMounted(()=>{
      getSaleList({token:formState.token}).then(res=>{
        // console.log(res);
        saleList.value = res;
      });
    })

    const saleChange = (e)=>{
      console.log(e)
      console.log(formState)
      formState.member_id = e+'';
    }

    const rules = {
      // member_id: [{ required: true, message: '请选择销售人员', trigger: 'change' }],
      // company: [
      //     { required: true, message: '请输入公司名称', trigger: 'blur' },
      //     { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
      // ],
      customer_brand: [{ required: true, message: '请输入参展品牌', trigger: 'blur' }],
      // display_content: [{ required: true, message: '请输入展示类容', trigger: 'blur' }],
      // price: [{ required: true, message: '请输入价格', trigger: 'blur' }]
    };

    const handleOk = () => {
      console.log(formState)
      formRef.value
        .validate()
        .then(() => {
          //提交
          confirmLoading.value = true;
          setTimeout(() => {
            store.commit("setBuyPopupIsShow",false);
            confirmLoading.value = false;
          }, 1000);
          console.log('values', formState, toRaw(formState));
        })
        .catch(error => {
          console.log('error', error);
        });
    };

    const handleCancel = ()=>{
      console.log("关闭弹出层")
      formRef.value.resetFields();
      store.commit("setBuyPopupIsShow",false);
    }

    const onSubmit = () => {
      formRef.value
        .validate()
        .then(() => {
          console.log('values', formState, toRaw(formState));
        })
        .catch(error => {
          console.log('error', error);
        });
    };

    //兼容弹出层状态，状态变化时候更新表单内容
    watch(buyPopupIsShow, function(news,old){
      if(!news){
        return false;
      }
      console.log('watch 弹出层状态发生变化');
      console.log(buyPopupData.value);
      const rdata = buyPopupData.value;
      formState.title = rdata.title;
      formState.showroom_id = rdata.id;

      rectStatus.value = rdata.status; //设置展位状态

      if(rdata.status===1){
        isEdit.value = false;
        // formTitle.value="购买展位";
        formState.member_id = null;
        formState.member_name = null;
        // console.log("购买展位")
      }
      if(rdata.status===3||rdata.status===2){
        isEdit.value = true;
        // formTitle.value="编辑展位";
        // console.log("编辑展位");
        if(rdata.status===2){
          formTitle.value="展位详情";
        }

        formState.member_id = rdata.member.id+'';
        formState.member_name = rdata.member.name;
        console.log(rdata.member.id);

        console.log(formState);
        formState.company = rdata.order_form.company;
        formState.customer_brand =rdata.order_form.customer_brand;
        formState.display_content = rdata.order_form.display_content;
        formState.remarks = rdata.order_form.remarks;
        formState.price = rdata.order_form.price;
        formState.order_id = rdata.order_form.id;
      }
    })

    const buyCheck = ()=>{
      console.log(formState);
      console.log(formState.company);
      // if(formState.company===""){
      //   message.error("请输入客户名！");
      //   return false;
      // }

      if(formState.member_id===null){
        message.error("请选择销售人员！");
        return false;
      }
      if(formState.customer_brand===""){
        message.error("请输入参展品牌！");
        return false;
      }
      // if(formState.display_content===""){
      //   message.error("请输入展示内容！");
      //   return false;
      // }
      // if(formState.price===""||formState.price==="0.00"){
      //   message.error("请输入价格！");
      //   return false;
      // }
      return true;
    }

    const sub = (status)=>{
      if(!buyCheck()){
        return false;
      }
      formState.status = status;
      formRef.value
        .validate()
        .then(() => {
          console.log('values', formState, toRaw(formState));
          createOrder(toRaw(formState)).then(res=>{
              store.dispatch("getLists",selectCategory.value.id);
          })
          formRef.value.resetFields();
          store.commit("setBuyPopupIsShow",false);
        })
        .catch(error => {
          console.log('error', error);
        });
    }

    const update = (status) => {
       if(status===2&&!buyCheck()){
        return false;
      }
      formState.status = status;
      formRef.value
        .validate()
        .then(() => {
          console.log('values', formState, toRaw(formState));
          updateOrder(toRaw(formState)).then(res=>{
              store.dispatch("getLists",selectCategory.value.id);
          })
          formRef.value.resetFields();
          store.commit("setBuyPopupIsShow",false);
        })
        .catch(error => {
          console.log('error', error);
        });
    }

    const exportBooth = ()=>{
      formRef.value
        .validate()
        .then(() => {
          localStorage.setItem("customer_brand",formState.customer_brand);
          emit('exportBooth',toRaw(formState))
        })
        .catch(error => {
          console.log('error', error);
        });
    }

    return {
      formTitle,
      buyPopupIsShow,
      confirmLoading,
      rectStatus,
      handleOk,
      handleCancel,
      formRef,
      other: '',
      formState,
      rules,
      onSubmit,
      sub,
      update,
      saleList,
      saleChange,
      isEdit,isAdmin,isSell,isCaiwu,isControl,
      exportBooth
    };
  },
});
</script>

<style scoped>
.dialog-footer{text-align: center;}
.dialog-footer button{margin:0 5px;}
</style>