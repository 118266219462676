<template>
 	<div class="map">
 		<!-- <div class="headbox" :style="{width:mapStyle.inner.width}">
	      <div class="tips">
	        <div class="normal">未出售</div>
	        <div class="lock">锁定中</div>
	        <div class="finish">已出售</div>
	      </div>
	      <div class="toolbox">
	        <div class="edit" @click="openEdit" v-if="isAdmin">DIY展位</div>
	        <div class="edit" @click="openExport" v-if="isAdmin">导出</div>
	        <span style="margin-right:4px;">标尺</span>
	        <a-switch 
	        	size="small" 
	        	@change="rulerSwitch" 
	        	:checked="switchValue"/>
	      </div>
	   </div> -->
	   <div class="canvasbox" :style="mapStyle.outer">
		   <div id="container" :style="mapStyle.inner"></div>
    	</div>

		

 	</div>
</template>

<script setup>
import { defineProps, ref, watch, computed, onMounted } from 'vue'
// import Konva from 'konva';
// import {Stage, Layer, Circle} from 'konva';
import {useStore} from 'vuex'
import {useGetMapStyle} from '@/use/diy-use.js'

import {drawCanvas} from './index.js'


const props = defineProps({
  cate: {type:Object, default:{}},
  list: {type:Array, default:[]},
  editVisible:{type:Boolean, default:false}
})

const isAdmin = ref(true);
const switchValue = ref(true);
const canvasWidth = ref(1500);
const canvasHeight = ref(936);

const store = useStore();
const lists = computed(() => store.state.lists);  //展位列表
const lineLists = computed(() => store.state.lineLists);  //展位标尺
const selectCategory = computed(() => store.state.selectCategory); //展馆信息
const rulerIsShow = computed(() => store.state.rulerIsShow);




const bg = ref('');
const canvasMargin = ref({});
const mapStyle =ref({});
mapStyle.value = useGetMapStyle(selectCategory.value);
canvasWidth.value = mapStyle.value.canvasWidth;
canvasHeight.value = mapStyle.value.canvasHeight;

window.onresize = ()=>{
	console.log('window resize');
	mapStyle.value = useGetMapStyle(selectCategory.value);
	canvasWidth.value = mapStyle.value.canvasWidth;
	canvasHeight.value = mapStyle.value.canvasHeight;
	init();
}

//监控store中的数据
watch([selectCategory,rulerIsShow], (newValue, oldValue) => {
	console.log("store中的数据发生改变 selectCategory");
    bg.value = selectCategory.value.picture;
	//动态计算宽高
	mapStyle.value = useGetMapStyle(selectCategory.value);
	canvasWidth.value = mapStyle.value.canvasWidth;
	canvasHeight.value = mapStyle.value.canvasHeight;
	init();
})

// 此种监控方式容易造成内存泄露
// watch([lists,rulerIsShow,lineLists], (newValue, oldValue) => {
// 	console.log("store中的数据发生改变 lists,rulerIsShow");
// 	//动态计算宽高
// 	mapStyle.value = useGetMapStyle(selectCategory.value);
// 	canvasWidth.value = mapStyle.value.canvasWidth;
// 	canvasHeight.value = mapStyle.value.canvasHeight;
// 	init();
// })

let isChange = false;
watch([lists,lineLists], () => {
	isChange = true
})


watch([lists,lineLists,props], () => {
	console.log("props:",props.editVisible)
	console.log("store中的数据发生改变 lists,rulerIsShow",!props.editVisible,isChange);
	
	//动态计算宽高
	if(!props.editVisible&&isChange){
		mapStyle.value = useGetMapStyle(selectCategory.value);
		canvasWidth.value = mapStyle.value.canvasWidth;
		canvasHeight.value = mapStyle.value.canvasHeight;
		console.log("更新图层")

		init();
		setTimeout(()=>{
			isChange = false;
		},200)
	}
})







const rulerSwitch = ()=>{
	switchValue.value = switchValue.value?false:true;
	store.commit("setRulerIsShow",switchValue.value);
}

//初始化
const init = ()=>{
	// localStorage.setItem("canvasWidth",canvasWidth.value)
	// localStorage.setItem("canvasHeight",canvasHeight.value)
	drawCanvas.init(canvasWidth.value,canvasHeight.value);
}


onMounted(()=>{
	return {
		bg,
		switchValue,
		rulerSwitch
	}
})


</script>

<style scoped>
/* .map{width:1500px; display: flex; min-height:calc(100vh - 161px); flex-direction: column; justify-content: center; align-items: center;} */
.map .canvasbox{flex:0 0 auto; width:1500px; height:936px; border:1px solid #eee; /*background:url(../../../assets/map/map0101.gif) no-repeat; background-size:100% 100%;*/ position: relative; margin:10px 0;}

.map .headbox{width:95%; display: flex; justify-content: space-between;}
.map .tips{margin-bottom:10px; margin-top:20px; display:flex;}
.map .tips>div{padding-left:30px; position: relative; font-size:12px; line-height:14px; margin:0 5px; }
.map .tips>div:before{content:''; display:block; width:25px; height:12px; border:1px solid #eee; position: absolute; top:0px; left:0; border-radius: 4px;}
.map .tips>div.finish:before{background:#ffadad;}
.map .tips>div.lock:before{background:#ddd; }
.map .tips>div.choice:before{background:rgba(144,238,144,0.43);}
.map .toolbox{display:flex; align-items: center;}
.map .toolbox .tool{width:20px; height:20px; line-height:20px; margin-left:4px; border:1px dashed #666; text-align: center; cursor:pointer; font-size:15px;}
.map .toolbox .tool.che{background:rgba(144,238,144,0.43);}

.map .toolbox .edit{color:#409EFF; margin-right:10px; cursor:pointer;}
#container{position: relative;}
</style>