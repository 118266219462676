import { ref, reactive, toRefs,computed} from 'vue'
import store from '@/store/';

import {Stage, Layer, Group, Line, Text} from 'konva';



//width为canvas在页面的显示宽度的像素
let rulerLayer = null;
// export function drawPaddingRuler(width,height) {
export const drawPaddingRuler = {
    init:(width,height,stage)=>{
        rulerLayer = new Layer();
        drawPaddingRuler.draw(rulerLayer,width,height);
        stage.add(rulerLayer)
    },
    draw:(layer,width,height)=>{

        const selectCategory = computed(() => store.state.selectCategory);
        const lists = computed(() => store.state.lists);
        
        // let layer = new Layer();
        const radio = width/selectCategory.value.width;
        
        //根据展位内边距计算边距标尺
        const top = selectCategory.value.top_padding*radio;
        const bottom = selectCategory.value.bottom_padding*radio-2;
        const left = selectCategory.value.left_padding*radio;
        const right = selectCategory.value.right_padding*radio+1;
        const vwidth = width - left -right;
        const vheight = height -top -bottom;

        const org = {x:0, y:0};

        console.log(selectCategory.value.left_padding, left)

        let strokeWidth = 1;
        let fill = '#333';
        let stroke = '#333';
        let fontSize = 9;

        /* ----横向---- */
        //左上
        layer.add(new Line({
            points: [org.x, org.y+top, org.x+left, org.y+top],
            stroke,
            strokeWidth
        }));
        selectCategory.value.left_padding>1600&&layer.add(new Text({
            x:org.x, y:org.y+top+3,
            width:left,
            text:selectCategory.value.left_padding,
            fill, fontSize, strokeWidth,
            align:'center'
        }));
        layer.add(new Line({
            points: [org.x+3, org.y+top-3, org.x-3, org.y+top+3],
            stroke, strokeWidth
        }));
        layer.add(new Line({
            points: [org.x+left+3, org.y+top-3, org.x+left-3, org.y+top+3],
            stroke, strokeWidth
        }));


        //左下
        layer.add(new Line({
            points: [org.x, org.y+height-bottom, org.x+left, org.y+height-bottom],
            stroke,
            strokeWidth
        }));
        selectCategory.value.left_padding>1600&&layer.add(new Text({
            x:org.x, y:org.y+height-bottom-14,
            width:left,
            height:12,
            text:selectCategory.value.left_padding,
            fill, fontSize, strokeWidth,
            align:'center',verticalAlign:'top'
        }));
        layer.add(new Line({
            points: [org.x+3, org.y+height-bottom-3, org.x-3, org.y+height-bottom+3],
            stroke, strokeWidth
        }));
        layer.add(new Line({
            points: [org.x+left+3, org.y+height-bottom-3, org.x+left-3, org.y+height-bottom+3],
            stroke, strokeWidth
        }));

        // 右上
        layer.add(new Line({
            points: [org.x+width-right, org.y+top, org.x+width, org.y+top],
            stroke,
            strokeWidth
        }));
        selectCategory.value.right_padding>1600&&layer.add(new Text({
            x:org.x+width-right, y:org.y+top+3,
            width:right,
            text:selectCategory.value.right_padding,
            fill, fontSize, strokeWidth,
            align:'center'
        }));
        layer.add(new Line({
            points: [org.x+width-right+3, org.y+top-3, org.x+width-right-3, org.y+top+3],
            stroke, strokeWidth
        }));
        layer.add(new Line({
            points: [org.x+width+3, org.y+top-3, org.x+width-3, org.y+top+3],
            stroke, strokeWidth
        }));

        // 右下
        layer.add(new Line({
            points: [org.x+width-right, org.y+height-bottom, org.x+width, org.y+height-bottom],
            stroke,
            strokeWidth
        }));
        selectCategory.value.right_padding>1600&&layer.add(new Text({
            x:org.x+width-right, y:org.y+height-bottom-14,
            width:right,
            text:selectCategory.value.right_padding,
            fill, fontSize, strokeWidth,
            align:'center'
        }));
        layer.add(new Line({
            points: [org.x+width-right+3, org.y+height-bottom-3, org.x+width-right-3, org.y+height-bottom+3],
            stroke, strokeWidth
        }));
        layer.add(new Line({
            points: [org.x+width+3, org.y+height-bottom-3, org.x+width-3, org.y+height-bottom+3],
            stroke, strokeWidth
        }));


        /* ----纵向---- */
        //左上
        layer.add(new Line({
            points: [org.x+left, org.y,org.x+left, org.y+top],
            stroke, strokeWidth
        }));
        selectCategory.value.top_padding>1600&&layer.add(new Text({
            x:org.x+left+12, y:org.y,
            width:top,
            text:selectCategory.value.top_padding,
            fill, fontSize, strokeWidth,
            align:'center',
            rotation:90
        }));
        layer.add(new Line({
            points: [org.x+left+3,org.y-3, org.x+left-3, org.y+3],
            stroke, strokeWidth
        }));

        //左下
        layer.add(new Line({
            points: [org.x+left, org.y+height-bottom, org.x+left, org.y+height],
            stroke, strokeWidth
        }));
        selectCategory.value.bottom_padding>1600&&layer.add(new Text({
            x:org.x+left+12, y:org.y+height-bottom,
            width:bottom,
            text:selectCategory.value.bottom_padding,
            fill, fontSize, strokeWidth,
            align:'center',
            rotation:90
        }));
        layer.add(new Line({
            points: [org.x+left+3,org.y+height-3, org.x+left-3, org.y+height+3],
            stroke, strokeWidth
        }));

        //右上
        layer.add(new Line({
            points: [org.x+width-right, org.y, org.x+width-right, org.y+top],
            stroke, strokeWidth
        }));
        selectCategory.value.top_padding>1600&&layer.add(new Text({
            x:org.x+width-right-3, y:org.y,
            width:top,
            text:selectCategory.value.top_padding,
            fill, fontSize, strokeWidth,
            align:'center',
            rotation:90
        }));
        layer.add(new Line({
            points: [org.x+width-right+3,org.y-3,org.x+width-right-3, org.y+3],
            stroke, strokeWidth
        }));

        //右下
        layer.add(new Line({
            points: [org.x+width-right, org.y+height-bottom, org.x+width-right, org.y+height],
            stroke, strokeWidth
        }));
        selectCategory.value.bottom_padding>1600&&layer.add(new Text({
            x:org.x+width-right-3, y:org.y+height-bottom,
            width:bottom,
            text:selectCategory.value.bottom_padding,
            fill, fontSize, strokeWidth,
            align:'center',
            rotation:90
        }));
        layer.add(new Line({
            points: [org.x+width-right+3, org.y+height-3, org.x+width-right-3, org.y+height+3],
            stroke, strokeWidth
        }));
        
        return layer
    },
    update:(width,height)=>{
        drawPaddingRuler.removeAll();
        drawPaddingRuler.draw(rulerLayer,width,height)
    },
    removeAll:()=>{
        rulerLayer && rulerLayer.destroyChildren();
    }
}