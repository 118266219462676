import {computed} from 'vue'
import store from '@/store/';

//width为canvas在页面的显示宽度的像素
export function calcRuler() {
    let width=localStorage.getItem('canvasWidth')*1;
    let height=localStorage.getItem('canvasHeight')*1;
    const selectCategory = computed(() => store.state.selectCategory);
    const lists = computed(() => store.state.lists);
    const radio = width/selectCategory.value.width;
    let distW = [];
    let distH = [];
    lists.value.map((item)=>{
        // console.log(item)
        let itemRight = item.left+item.width;
        let itemTop = item.top;
        let itemLeft = item.left;
        let itemBottom = itemTop+item.height;
        let minDist = null;
        let minHDist = null;
        lists.value.map((item2)=>{
            //检测水平位置展位间距
            if(Math.abs(itemTop-item2.top)<50 && item2.left >= itemRight){
                minDist === null && (minDist = item2.left - itemRight);
                if(item2.left - itemRight<minDist){
                    minDist = item2.left - itemRight;
                }
            }
            //检测垂直方向展位距离
            if(Math.abs(itemLeft-item2.left)<50 && item2.top >= itemBottom){
                minHDist === null && (minHDist = item2.top - itemBottom);
                
                if(item2.top - itemBottom<minHDist){
                    minHDist = item2.top - itemBottom;
                }
            }
        })

        //横向标尺
        if(minDist!==null&&minDist<16000&&minDist>2000){
            // distW.push({top:itemTop,left:itemRight,width:minDist});
            let rtop = Math.round(item.top*radio);
            let bool = distW.findIndex((item)=>{
                return item.left===itemRight && item.width === minDist &&  Math.abs(item.top - itemTop)<30000
            })
            if(rtop<height/2){
                bool===-1 &&distW.push({top:itemTop+item.height,left:itemRight,width:minDist,direction:'hor'});
            }
            if(rtop>height/2){
                bool===-1 &&distW.push({top:itemTop,left:itemRight,width:minDist,direction:'hor'});
            }
        }
        //竖向标尺
        if(minHDist!==null&&minHDist<16000&&minHDist>2000){
            let bool = distH.findIndex((item)=>{
                return item.top===itemBottom && item.height === minHDist &&  Math.abs(item.left - itemLeft)<50000
            })
            
            bool===-1 && distH.push({top:itemBottom,left:itemLeft,width:minHDist,direction:'ver'});
        }
    })
    return [...distH,...distW]
}

