import { ref, reactive, toRefs, computed} from 'vue'

import {Stage, Layer, Circle} from 'konva';

import {drawRect} from './rect.js'
import {drawPaddingRuler} from './paddingRuler.js'
import {drawDist} from './dist.js'

import store from '@/store/';

export let stage=null;

export const drawCanvas = {
    init:(width,height)=>{
        if(stage){
            console.log("已经存在stage对象")
            stage.setAttrs({width:width,height:height})
            drawCanvas.updateRect(width,height)
            return false;
        }else{
            console.log("初始化stage对象")
            stage = new Stage({
                container: 'container', 
                width: width,
                height: height

            });
            drawCanvas.load(width,height);
        }

    },
    load:(width,height)=>{
        // const rulerIsShow = computed(() => store.state.rulerIsShow);

         drawRect.init(width,height,stage);
         drawDist.init(width,height,stage);
         drawPaddingRuler.init(width,height,stage)

        //  let paddingRulerLayer = drawPaddingRuler(width,height);
        //  let distLayer = drawDist(width,height);
        //  rulerIsShow.value && stage.add(distLayer);
        //  stage.add(paddingRulerLayer);
        //  paddingRulerLayer.draw();
        //  rulerIsShow.value && distLayer.draw();

         stage.draw();


        const loadImage = (url)=>{
            return new Promise(function(resolve, reject) {
                const img = new Image();
                img.onload = function(){
                    resolve(img);
                };
                img.onerror = function() {
                    reject(new Error('Could not load image at ' + url));
                };
                img.src = url;
            });
        }
        async function redraw(){
            await loadImage(require('@/assets/icon/add.png'));
            await loadImage(require('@/assets/icon/eyes.png'));
            await loadImage(require('@/assets/icon/edit.png'));
            // rectLayer.draw();
            // stage.add(paddingRulerLayer);
            stage.draw();
        }
        redraw()
    },
    updateRect:(width,height)=>{
        drawRect.update(width,height,stage)
        drawDist.update(width,height,stage)
        drawPaddingRuler.update(width,height,stage)
        stage.draw();
        stage.draw();

    }
}