import {computed} from 'vue'
import store from '@/store/';

import {Layer, Line, Text} from 'konva';

let distLayer = null;
//width为canvas在页面的显示宽度的像素
export const drawDist = {
    init:(width,height,stage)=>{
        distLayer = new Layer();
        drawDist.draw(distLayer,width,height);
        stage.add(distLayer)
    },
    draw:(layer,width,height)=>{
        const rulerIsShow = computed(() => store.state.rulerIsShow);
        if(!rulerIsShow.value){
            return false;
        }

        const selectCategory = computed(() => store.state.selectCategory);
        const lists = computed(() => store.state.lineLists);
        const radio = width/selectCategory.value.width;
        let distW = [];
        let distH = [];
        let strokeWidth = 0.5;
        let fill = '#666';
        let stroke = '#666';
        let fontSize = 10;
        let offset = 850*radio;
        lists.value.map((item)=>{
            if(item.direction==='hor'){
                distW.push(item)
            }else{
                distH.push(item)
            }
        })

        distH.map((item)=>{
            // console.log(item)
            let rleft = Math.round(item.left*radio);
            let rtop = Math.round(item.top*radio);
            let rheight = Math.round(item.width*radio);
            //画左侧标尺
            layer.add(new Line({
                // points: [0-offset,0,0-offset,0+rheight],
                points: [rleft-offset, rtop, rleft-offset, rtop+rheight],
                stroke,
                strokeWidth
            }));
            layer.add(new Line({
                points: [rleft-offset-4, rtop, rleft-4, rtop],
                stroke,
                strokeWidth
            }));
            layer.add(new Line({
                points: [rleft-offset-4, rtop+rheight, rleft-4, rtop+rheight],
                stroke,
                strokeWidth
            }));
            layer.add(new Text({
                x:rleft-offset-4, y:rtop,
                width:rheight,
                text:item.width,
                fill, fontSize, strokeWidth,
                align:'center',
                rotation:90
            }));
            layer.add(new Line({
                points: [rleft-offset-3,rtop-3, rleft-offset+3,rtop+3],
                stroke, strokeWidth
            }));
            layer.add(new Line({
                points: [rleft-offset-3,rtop+rheight-3, rleft-offset+3,rtop+rheight+3],
                stroke, strokeWidth
            }));
        })

        distW.map((item)=>{
            let rleft = Math.round(item.left*radio);
            let rtop = Math.round(item.top*radio);
            // let rheight = Math.round(item.height*radio);
            let rheight = 0;
            let rwidth = Math.round(item.width*radio);

            //画下面侧标尺 ----上半部分展位
            if(rtop<height/2){
                layer.add(new Line({
                    points: [rleft+0, rtop+rheight+offset, rleft+rwidth, rtop+rheight+offset],
                    stroke,
                    strokeWidth
                }));
                layer.add(new Line({
                    points: [rleft+0, rtop+rheight+5, rleft+0, rtop+rheight+offset+5],
                    stroke,
                    strokeWidth
                }));
                layer.add(new Line({
                    points: [rleft+rwidth, rtop+rheight+5, rleft+rwidth, rtop+rheight+offset+5],
                    stroke,
                    strokeWidth
                }));
                layer.add(new Text({
                    x:rleft+0, y:rtop+rheight+offset+4,
                    width:rwidth,
                    text:item.width,
                    fill, fontSize, strokeWidth,
                    align:'center',
                }));
                layer.add(new Line({
                    points: [rleft+3, rtop+rheight+offset-3, rleft-3, rtop+rheight+offset+3],
                    stroke, strokeWidth
                }));
                layer.add(new Line({
                    points: [rleft+rwidth+3, rtop+rheight+offset-3, rleft+rwidth-3, rtop+rheight+offset+3],
                    stroke, strokeWidth
                }));
            }

            //画上面侧标尺 ----下半部分展位
            if(rtop>height/2){
                layer.add(new Line({
                    points: [rleft+0, rtop-offset, rleft+rwidth, rtop-offset],
                    stroke,
                    strokeWidth
                }));
                layer.add(new Line({
                    points: [rleft+0, rtop-5, rleft+0, rtop-offset-5],
                    stroke,
                    strokeWidth
                }));
                layer.add(new Line({
                    points: [rleft+rwidth,rtop-5, rleft+rwidth, rtop-offset-5],
                    stroke,
                    strokeWidth
                }));
                layer.add(new Text({
                    x:rleft+0, y:rtop-offset-fontSize-4,
                    width:rwidth,
                    text:item.width,
                    fill, fontSize, strokeWidth,
                    align:'center',
                }));
                layer.add(new Line({
                    points: [rleft+3, rtop-offset-3, rleft-3, rtop-offset+3],
                    stroke, strokeWidth
                }));
                layer.add(new Line({
                    points: [rleft+rwidth+3, rtop-offset-3, rleft+rwidth-3, rtop-offset+3],
                    stroke, strokeWidth
                }));
            }
        })

        return layer
    },
    update:(width,height)=>{
        drawDist.removeAll();
        drawDist.draw(distLayer,width,height)
    },
    removeAll:()=>{
        distLayer && distLayer.destroyChildren();
    }
}
